import {
  Avatar,
  Button,
  Card,
  Carousel,
  Col,
  Collapse,
  Divider,
  Row,
} from "antd";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Cards } from "../../components/cards/frame/cards-frame";
import Heading from "../../components/heading/heading";

import Link from "antd/lib/typography/Link";
import {
  ChartjsAreaChart,
  ChartjsBarChartGrad,
} from "../../components/charts/chartjs";
import { chartLinearGradient } from "../../components/utilities/utilities";
import "./style.css";

import { UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ADMIN,
  CAPTAIN,
  IMAGE_URL_PREFIX,
  SUPERADMIN,
} from "../../config/dataService/dataService";
import {
  getDashboardData,
  getRecentTransactions,
} from "../../redux/Dashboard/actionCreatore";
import income from "../../static/img/income.png";
import outcome from "../../static/img/outcome.png";
import { UserCard } from "./style";

import { getItem } from "../../utility/localStorageControl";
import AdminRidesCount from "./AdminRidesCount";
import CaptainRidesCount from "./CaptainRidesCount";
import SuperAdminRidesCount from "./SuperAdminRidesCount";
const { Panel } = Collapse;
const Dashboard = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const [time, setTime] = useState("weekly");
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState("");
  const [toggleButton, setToggleButton] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = getItem("user_details");
  const [chartLabels, setChartLabels] = useState([]);
  const [completedRidesForChart, setCompletedRidesForChart] = useState([]);
  const [cancelledRidesForChart, setCancelledRidesForChart] = useState([]);
  const [appDriversForChart, setAppDriversForChart] = useState([]);
  const [driverCollectionForChart, setDriversCollectionForChart] = useState([]);

  const [appRidersForChart, setAppRidersForChart] = useState([]);
  const [appRiderDriverFilter, setAppRiderDriverFilter] = useState("weekly");
  const [appRiderDriverLabels, setAppRiderDriverLabels] = useState([]);

  const handleChangeTimeFilter = async (selectedTime) => {
    console.log(selectedTime);
    setTime(selectedTime);
    await fetchDashboard(selectedTime, appRiderDriverFilter);
  };

  const handleChangeAppDriverRiderTimeFilter = async (selectedTime) => {
    console.log(selectedTime);
    setAppRiderDriverFilter(selectedTime);
    await fetchDashboard(time, selectedTime);
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    window.scrollTo(0, 0);
    return () => window.removeEventListener("resize", handleResize);
  }, [location]);

  const { data, isLoading } = useQuery(
    "dashboard",
    () => {
      return fetchDashboard(time, appRiderDriverFilter);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: recentTransactiondata, isLoading: receenTransactionLoading } =
    useQuery(
      "recentTransaction",
      () => {
        return fetchDashboardRecentTransaction();
      },
      {
        refetchOnWindowFocus: false,
      }
    );

  const fetchDashboardRecentTransaction = async () => {
    await dispatch(getRecentTransactions());
  };

  const transactions = useSelector(
    (state) => state.dashboard.recentTransaction
  );

  const fetchDashboard = async (time, appRiderDriverFilter) => {
    const resp = await dispatch(getDashboardData(time, appRiderDriverFilter));
    if (resp) {
      calculateRideCounts(resp, time, appRiderDriverFilter);
    }
  };

  const dashboard = useSelector((state) => state.dashboard.dashboardData);
  const CardGroup = lazy(() => import("./overview/business/CardGroup"));
  const CashFlow = lazy(() => import("./overview/business/CashFlow"));
  const IncomeAndExpenses = lazy(() =>
    import("./overview/business/IncomeAndExpenses")
  );
  const performanceState = {
    user1: [10, 5, 5, 45, 40, 20, 30],
    user2: [20, 60, 50, 45, 50, 60, 70],
  };

  const calculateRideCounts = async (data, time, appRiderDriverFilter) => {
    // console.log("data", data);
    let labels = [];
    let appLabels = [];
    {
      time == "yearly" &&
        (labels = data?.cancleRides?.map((item) => item.year));
    }
    {
      time == "monthly" &&
        (labels = data?.cancleRides?.map((item) => item.month + item.year));
    }

    {
      time == "weekly" &&
        (labels = data?.cancleRides?.map((item) => item.date));
    }
    {
      appRiderDriverFilter == "yearly" &&
        (appLabels = data?.appDriver?.map((item) => item.year));
    }
    {
      appRiderDriverFilter == "monthly" &&
        (appLabels = data?.appDriver?.map((item) => item.month + item.year));
    }

    {
      appRiderDriverFilter == "weekly" &&
        (appLabels = data?.appDriver?.map((item) => item.date));
    }

    let appDrivers = data?.appDriver?.map((item) => item.total);
    let appRiders = data?.appRider?.map((item) => item.total);
    let driverCollection = data?.driverCollection?.map((item) => item.total);

    setAppRiderDriverLabels(appLabels?.reverse());

    setAppDriversForChart(appDrivers?.reverse());
    setAppRidersForChart(appRiders?.reverse());
    setDriversCollectionForChart(driverCollection?.reverse());
    let cancleRides = data?.cancleRides?.map((item) => item.total);
    let compeleteRidesCount = data?.compeleteRides?.map((item) => item.total);

    setChartLabels(labels?.reverse());

    setCompletedRidesForChart(compeleteRidesCount?.reverse());
    setCancelledRidesForChart(cancleRides?.reverse());
  };

  return (
    <>
      <div
        className="common_layout content-container "
        style={
          {
            // margin: windowWidth > 991 ? "25px 100px 25px 100px" : "25px",
          }
        }
      >
        <h1 className="heading">{t("gAutoDashboard")}</h1>
        {/* <BreadCrumb /> */}

        <Divider />
        <Row style={{ marginBottom: "20px" }}>
          <Col lg={6} md={12} sm={12} xs={24}>
            <Card className="counts">
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="icon-box" style={{ background: "#69a5ff24" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="blue"
                        d="M6 22q-.825 0-1.413-.588T4 20V4q0-.825.588-1.413T6 2h12q.825 0 1.413.588T20 4v16q0 .825-.588 1.413T18 22H6Zm5-11l2.5-1.5L16 11V4h-5v7Z"
                      />
                    </svg>
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="count">
                    {dashboard?.bookings
                      ? dashboard?.bookings?.toLocaleString()
                      : "0"}
                  </div>
                  <div className="countof">Bookings</div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col lg={6} md={12} sm={12} xs={24}>
            <Card className="counts">
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="icon-box" style={{ background: "#4bff592e" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="green"
                        d="M6 19v1q0 .425-.288.713T5 21H4q-.425 0-.713-.288T3 20v-8l2.1-6q.15-.45.537-.725T6.5 5h11q.475 0 .863.275T18.9 6l2.1 6v8q0 .425-.287.713T20 21h-1q-.425 0-.713-.288T18 20v-1H6Zm-.2-9h12.4l-1.05-3H6.85L5.8 10Zm1.7 6q.625 0 1.063-.438T9 14.5q0-.625-.438-1.063T7.5 13q-.625 0-1.063.438T6 14.5q0 .625.438 1.063T7.5 16Zm9 0q.625 0 1.063-.438T18 14.5q0-.625-.438-1.063T16.5 13q-.625 0-1.063.438T15 14.5q0 .625.438 1.063T16.5 16Z"
                      />
                    </svg>
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="count">
                    {dashboard?.driverRegister
                      ? dashboard?.driverRegister?.toLocaleString()
                      : "0"}
                  </div>
                  <div className="countof">Drivers Register</div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col lg={6} md={12} sm={12} xs={24}>
            <Card className="counts">
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="icon-box" style={{ background: "#ffed6924" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="40px"
                      width="40px"
                      viewBox="0 0 32 30.665"
                    >
                      <g id="graph" transform="translate(-8 -8.835)">
                        <g id="bold_1_" transform="translate(8 8.835)">
                          <g
                            id="Group_1113"
                            data-name="Group 1113"
                            transform="translate(0 0)"
                          >
                            <path
                              id="Union_7"
                              data-name="Union 7"
                              d="M.39,12.942a1.336,1.336,0,0,1,0-1.886L8.347,3.1,6.957,1.708A1,1,0,0,1,7.666,0h4.665a1,1,0,0,1,1,1V5.666a1,1,0,0,1-1.708.707l-1.39-1.39L2.276,12.942a1.336,1.336,0,0,1-1.886,0Z"
                              transform="translate(2.669 0)"
                              fill="orange"
                              opacity="0.5"
                            ></path>
                            <g
                              id="Group_1112"
                              data-name="Group 1112"
                              transform="translate(1.334 6.665)"
                            >
                              <path
                                id="Path_1026"
                                data-name="Path 1026"
                                d="M16.667,19.166v7.666h-8V19.166A1.666,1.666,0,0,1,10.333,17.5H15A1.668,1.668,0,0,1,16.667,19.166Z"
                                transform="translate(-8.667 -6.834)"
                                fill="orange"
                              ></path>
                              <path
                                id="Path_1027"
                                data-name="Path 1027"
                                d="M22,16.5v13H14v-13a1.666,1.666,0,0,1,1.666-1.666h4.666A1.665,1.665,0,0,1,22,16.5Z"
                                transform="translate(-3.334 -9.5)"
                                fill="orange"
                              ></path>
                              <path
                                id="Path_1028"
                                data-name="Path 1028"
                                d="M27.333,13.833V32.167h-8V13.833A1.666,1.666,0,0,1,21,12.167h4.668A1.666,1.666,0,0,1,27.333,13.833Z"
                                transform="translate(1.999 -12.167)"
                                fill="orange"
                              ></path>
                            </g>
                            <path
                              id="Path_1029"
                              data-name="Path 1029"
                              d="M39,25.167H9a1,1,0,0,1,0-2H39a1,1,0,1,1,0,2Z"
                              transform="translate(-8 5.498)"
                              fill="orange"
                              opacity="0.5"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </svg>{" "}
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="count">
                    {dashboard?.ridesComplete
                      ? dashboard?.ridesComplete?.toLocaleString()
                      : "0"}
                  </div>
                  <div className="countof">Rides Completed</div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col lg={6} md={12} sm={12} xs={24}>
            {" "}
            <Card className="counts">
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="icon-box">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#bd025d"
                        d="M17 18h4v-2h-4v-2l-3 3l3 3v-2M11 4C8.8 4 7 5.8 7 8s1.8 4 4 4s4-1.8 4-4s-1.8-4-4-4m0 10c-4.4 0-8 1.8-8 4v2h9.5c-.3-.8-.5-1.6-.5-2.5c0-1.2.3-2.3.9-3.4c-.6 0-1.2-.1-1.9-.1"
                      />
                    </svg>
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="count">
                    {dashboard?.userRegister
                      ? dashboard?.userRegister?.toLocaleString()
                      : "0"}
                  </div>
                  <div className="countof">Rider Register</div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {user?.role == SUPERADMIN && <SuperAdminRidesCount />}
        {user?.role == ADMIN && <AdminRidesCount />}
        {user?.role == CAPTAIN && <CaptainRidesCount />}
        <br />
        <Row justify=" " gutter={[20, 20]}>
          <Col lg={8} md={24} sm={24} xs={24}>
            {/* <Card>s</Card> */}
            <Card className="countCard1">
              <Row justify="space-evenly">
                <Col lg={24} md={24} sm={24} xs={24}>
                  <div
                    className="card-nav"
                    style={{ float: "right", marginBottom: "20px" }}
                  >
                    <ul>
                      <li
                        className={
                          appRiderDriverFilter === "Today"
                            ? "activebox"
                            : "FilterLink"
                        }
                      >
                        {/* <Link
                          // onClick={() => handleChangeTimeFilter("Today")}
                          to="#"
                          className={time === "Today" ? "active" : "FilterLink"}
                        >
                          Today
                        </Link> */}
                      </li>
                      <li
                        className={
                          appRiderDriverFilter === "weekly" ? "activebox  " : ""
                        }
                      >
                        <Link
                          onClick={() =>
                            handleChangeAppDriverRiderTimeFilter("weekly")
                          }
                          to="#"
                          className={
                            appRiderDriverFilter === "weekly"
                              ? " active"
                              : "FilterLink"
                          }
                        >
                          Week
                        </Link>
                      </li>
                      <li
                        className={
                          appRiderDriverFilter === "monthly"
                            ? "activebox "
                            : "FilterLink"
                        }
                      >
                        <Link
                          onClick={() =>
                            handleChangeAppDriverRiderTimeFilter("monthly")
                          }
                          to="#"
                          className={
                            appRiderDriverFilter === "monthly"
                              ? " "
                              : "FilterLink"
                          }
                        >
                          Month
                        </Link>
                      </li>
                      <li
                        className={
                          appRiderDriverFilter === "yearly"
                            ? "activebox"
                            : "FilterLink"
                        }
                      >
                        <Link
                          onClick={() =>
                            handleChangeAppDriverRiderTimeFilter("yearly")
                          }
                          to="#"
                          className={
                            appRiderDriverFilter === "yearly"
                              ? ""
                              : "FilterLink"
                          }
                        >
                          Year
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <Divider />
                </Col>
                {/* <Col lg={24} md={24} sm={24} xs={24}>
                  <Card className="bg-card blur-card" style={{ height: "80px" }}>
                    <Select
                      defaultValue="filter"
                      style={{ width: "31%", margin: 5, marginBottom:30 }}
                    ></Select>

                    <Select
                      defaultValue="select city"
                      style={{ width: "31%", margin: 5 }}
                    ></Select>

                    <Select
                      defaultValue="select driver"
                      style={{ width: "31%", margin: 5 }}
                    ></Select>
                  </Card>
                </Col> */}
                <Col lg={22}>
                  <Cards
                    headless
                    title={
                      <span style={{ lineHeight: "35px" }}>
                        Driver Collection
                      </span>
                    }
                  >
                    <div className="focard-details growth-upward">
                      <Heading as="h2">
                        ₹{" "}
                        {dashboard?.totalDriverCollection
                          ? dashboard?.totalDriverCollection.toFixed(2)
                          : 0}
                      </Heading>
                      {/* <h2>0</h2> */}
                    </div>
                    <ChartjsAreaChart
                      id="grossProfit"
                      labels={appRiderDriverLabels}
                      datasets={[
                        {
                          data: driverCollectionForChart,
                          borderColor: "#20C997",
                          borderWidth: 3,
                          fill: true,
                          pointHoverBackgroundColor: "#20c997",
                          pointHoverBorderWidth: 0,
                          pointHoverBorderColor: "transparent",
                          backgroundColor: () =>
                            chartLinearGradient(
                              document.getElementById("grossProfit"),
                              80,
                              {
                                start: "#20C99712",
                                end: "#20C99702",
                              }
                            ),
                        },
                      ]}
                      height={40}
                    />
                  </Cards>
                </Col>
                <Col lg={22}>
                  <Cards
                    headless
                    title={
                      <span style={{ lineHeight: "35px" }}>App Drivers</span>
                    }
                  >
                    <div className="focard-details growth-upward">
                      <Heading as="h2">
                        {dashboard?.totalAppDriver
                          ? dashboard?.totalAppDriver
                          : 0}
                      </Heading>
                      {/* <p className="focard-status">
                        <span className="focard-status__percentage">
                          <FeatherIcon icon="arrow-up" /> 25%
                        </span>
                        <span>Since last month</span>
                      </p> */}
                    </div>
                    <ChartjsAreaChart
                      id="netProfit"
                      labels={appRiderDriverLabels}
                      datasets={[
                        {
                          data: appDriversForChart,
                          borderColor: "#5F63F2",
                          borderWidth: 3,
                          fill: true,
                          pointHoverBackgroundColor: "#5F63F2",
                          pointHoverBorderWidth: 0,
                          pointHoverBorderColor: "transparent",
                          backgroundColor: () =>
                            chartLinearGradient(
                              document.getElementById("netProfit"),
                              80,
                              {
                                start: "#5F63F212",
                                end: "#5F63F202",
                              }
                            ),
                        },
                      ]}
                      height={40}
                    />
                  </Cards>
                </Col>
                <Col lg={22}>
                  <Cards
                    headless
                    title={
                      <span style={{ lineHeight: "35px" }}>App Riders</span>
                    }
                  >
                    <div className="focard-details growth-upward">
                      <Heading as="h2">
                        {dashboard?.totalAppRider
                          ? dashboard?.totalAppRider
                          : 0}
                      </Heading>
                      {/* <p className="focard-status">
                        <span className="focard-status__percentage">
                          <FeatherIcon icon="arrow-up" /> 25%
                        </span>
                        <span>Since last month</span>
                      </p> */}
                    </div>
                    <ChartjsAreaChart
                      id="grossProfit"
                      labels={appRiderDriverLabels}
                      datasets={[
                        {
                          data: appRidersForChart,
                          borderColor: "orange",
                          borderWidth: 3,
                          fill: true,
                          pointHoverBackgroundColor: "orange",
                          pointHoverBorderWidth: 0,
                          pointHoverBorderColor: "transparent",
                          backgroundColor: () =>
                            chartLinearGradient(
                              document.getElementById("grossProfit"),
                              80,
                              {
                                start: "#f9f0e3",
                                end: "#f9f0e3",
                              }
                            ),
                        },
                      ]}
                      height={40}
                    />
                  </Cards>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col lg={16} md={24} sm={24} xs={24}>
            <Card
              title={
                <>
                  Rides Overview
                  <div
                    className="card-nav"
                    style={{ float: "right", marginBottom: "4px" }}
                  >
                    <ul>
                      {/* <li className={time === "Today" ? "active" : ""}>
                        <Link
                          onClick={() => handleChangeTimeFilter("Today")}
                          to="#"
                        >
                          Today
                        </Link>
                      </li> */}
                      <li className={time === "weekly" ? "active" : ""}>
                        <Link
                          onClick={() => handleChangeTimeFilter("weekly")}
                          to="#"
                        >
                          Week
                        </Link>
                      </li>
                      <li className={time === "monthly" ? "active" : ""}>
                        <Link
                          onClick={() => handleChangeTimeFilter("monthly")}
                          to="#"
                        >
                          Month
                        </Link>
                      </li>
                      <li className={time === "yearly" ? "active" : ""}>
                        <Link
                          onClick={() => handleChangeTimeFilter("yearly")}
                          to="#"
                        >
                          Year
                        </Link>
                      </li>
                    </ul>
                  </div>
                </>
              }
              headStyle={
                {
                  // background: "#69a5ff24",
                }
              }
            >
              {/* <Row gutter={20} style={{ float: "right" }}>
                <Col>
                  <Row>
                    <Col>
                      <CheckCircleOutlined
                        style={{
                          float: "right",
                          fontSize: 25,
                          color: "#5d5dfbf5",
                        }}
                      />
                    </Col>
                    <Col>
                      <p className="total">Completed</p>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <CloseCircleOutlined
                        style={{
                          float: "right",
                          fontSize: 25,
                          color: "#f35c98e3",
                        }}
                      />
                    </Col>
                    <Col>
                      <p className="total">Cancelled</p>
                    </Col>
                  </Row>
                </Col>
              </Row> */}

              <ChartjsBarChartGrad
                height={180}
                labels={chartLabels}
                datasets={[
                  {
                    label: "Completed",
                    data: completedRidesForChart,
                    backgroundColor: "#a5a5fa",
                  },
                  {
                    label: "Cancelled",
                    data: cancelledRidesForChart,
                    backgroundColor: "#fcb6d2",
                  },
                ]}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={24}>
            <Card
              title="Top Drivers"
              style={{ marginTop: 20 }}
              headStyle={
                {
                  // background: "#69a5ff24",
                }
              }
              // bodyStyle={{ background: "#F9F5E7" }}
            >
              <Carousel
                slidesToShow={
                  window.innerWidth > 656 ? 4 : window.innerWidth < 418 ? 1 : 2
                }
                // autoplay
                dots={false}
                infinite={false}
                arrows
                prevArrow={
                  <img src="https://api.iconify.design/emojione-monotone/left-arrow.svg" />
                }
                nextArrow={
                  <img src="https://api.iconify.design/emojione-monotone/right-arrow.svg" />
                }
              >
                {/* <Row gutter={24}> */}
                {dashboard &&
                  dashboard?.topDrivers?.map((drivers) => {
                    return (
                      <Col lg={24} key={drivers._id}>
                        <UserCard>
                          <Card className="user-card">
                            <Avatar
                              size={130}
                              icon={
                                drivers && drivers?.profile_image ? (
                                  <img
                                    src={
                                      IMAGE_URL_PREFIX + drivers?.profile_image
                                    }
                                    // crossOrigin="anonymous"
                                  />
                                ) : (
                                  <UserOutlined />
                                )
                              }
                            />
                            <figcaption>
                              <div className="card__content">
                                <Heading className="card__name" as="h6">
                                  <Link
                                    onClick={() => {
                                      navigate(
                                        `driver/manageDriver/driverDetails/${drivers._id}`
                                      );
                                    }}
                                    to="#"
                                  >
                                    {drivers?.name}
                                  </Link>
                                </Heading>
                              </div>

                              <div className="card__actions">
                                <Button
                                  onClick={() => {
                                    navigate(
                                      `driver/manageDriver/driverWallet/${drivers._id}`
                                    );
                                  }}
                                  size="default"
                                  type="white"
                                >
                                  Wallet
                                </Button>
                                <Button
                                  onClick={() => {
                                    navigate(
                                      `driver/manageDriver/driverDetails/${drivers._id}`
                                    );
                                  }}
                                  size="default"
                                  type="white"
                                >
                                  Profile
                                </Button>
                              </div>
                              <Divider />
                              <div className="">
                                <Row gutter={15}>
                                  <Col xs={12}>
                                    <div className="info-single">
                                      <Heading
                                        className="info-single__title"
                                        as="h2"
                                      >
                                        {drivers?.totalRides}
                                      </Heading>
                                      <p>Total Rides</p>
                                    </div>
                                  </Col>

                                  <Col xs={12}>
                                    <div className="info-single">
                                      <Heading
                                        className="info-single__title"
                                        as="h2"
                                      >
                                        {Number.isInteger(
                                          drivers?.average_rating
                                        )
                                          ? drivers?.average_rating
                                          : drivers?.average_rating.toFixed(2)}
                                      </Heading>
                                      <p>Rating</p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </figcaption>
                          </Card>
                        </UserCard>
                      </Col>
                    );
                  })}
              </Carousel>
            </Card>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Card
              title="Total Rides"
              style={{ marginTop: 20 }}
              headStyle={
                {
                  // background:
                  //   "linear-gradient(90deg, rgba(254,111,144,0.6194852941176471) 0%, rgba(252,122,98,0.6530987394957983) 46%, rgba(255,141,64,0.5662640056022409) 77%)",
                }
              }
              // bodyStyle={{ background: "#F9F5E7" }}
            >
              <Col span={24}>
                <Collapse activeKey={activeKey}>
                  {dashboard &&
                    dashboard?.totalRides?.map((ride, index) => {
                      return (
                        <Panel
                          extra={ride?.totalRides}
                          header={
                            <div
                              onMouseEnter={() => setActiveKey(index)}
                              onMouseOut={() => setActiveKey("")}
                            >
                              {ride?.city_name}
                            </div>
                          }
                          key={index}
                          style={{
                            marginBottom: 10,
                            background: "white",
                          }}
                          showArrow={false}
                        >
                          <Card
                            style={{
                              marginBottom: 10,
                              background: "white",
                            }}
                          >
                            <Row>
                              {ride?.vehicles?.map((vehicle) => {
                                return (
                                  <Col span={6} key={vehicle?.vehicle_id}>
                                    <div
                                      style={{
                                        justifyContent: "center",
                                        display: "flex",
                                      }}
                                    >
                                      <span>
                                        {vehicle?.vehicle_name}{" "}
                                        <p>{vehicle?.totalRides}</p>
                                      </span>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          </Card>
                        </Panel>
                      );
                    })}
                </Collapse>
              </Col>
            </Card>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Card
              title="Recent transactions"
              style={{ marginTop: 20 }}
              headStyle={
                {
                  // background: "#69a5ff24",
                }
              }
              // bodyStyle={{ background: "#F9F5E7" }}
            >
              <div style={{ overflowY: "scroll", height: "40vh" }}>
                {Array.isArray(transactions) &&
                  transactions.map((trans) => {
                    return (
                      <Card
                        style={{
                          marginBottom: 10,
                          // background: "#69a5ff24",
                        }}
                        key={trans._id}
                      >
                        <Row
                          onDoubleClick={() =>
                            navigate(
                              `manageDriver/driver/driverWallet/${trans.user_id._id}`
                            )
                          }
                        >
                          <Col span={4}>
                            <Avatar
                              size={50}
                              icon={
                                <img
                                  src={
                                    IMAGE_URL_PREFIX +
                                    trans.user_id?.profile_image
                                  }
                                />
                              }
                              style={{ marginRight: 25 }}
                            />
                          </Col>
                          <Col span={14}>
                            {trans.user_id?.name} <br />
                            {new Date(trans.created_at).toLocaleString()}
                          </Col>
                          <Col span={6}>
                            <span
                              style={{
                                float: "right",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {" "}
                              <span>
                                {trans.type == "credit" ? "+" : "-"}{" "}
                                {trans?.amount?.toFixed(2)}
                                <br />
                                <b> {trans?.afterWalletBalance?.toFixed(2)}</b>
                              </span>
                              <img
                                src={trans.type == "credit" ? income : outcome}
                              />{" "}
                            </span>
                          </Col>
                        </Row>
                      </Card>
                    );
                  })}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default function WrappedDashboard() {
  return (
    <Suspense fallback="...loading">
      <Dashboard />
    </Suspense>
  );
}
