import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";

import {
  DeleteOutlined,
  EditOutlined,
  FileExcelOutlined,
  FileOutlined,
  FilePdfOutlined,
  PlusOutlined,
  SearchOutlined,
  SwapOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import useStateRef from "react-usestateref";
import {
  createAdminData,
  deleteAdminData,
  getAdminData,
  updateAdminData,
} from "../../../redux/admin/actionCreatore";
import { getCityData } from "../../../redux/Masters/City/actionCreatore";

import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  IMAGE_URL_PREFIX,
  SUPERADMIN,
} from "../../../config/dataService/dataService";
import Loader from "../../../container/loader/loader";
import { checkAction, getRandomColor } from "../../../utility/commonFunction";
import { getItem } from "../../../utility/localStorageControl";
import BreadCrumb from "../../BreadCrumb.js/BreadCrumb";
import { downloadReports } from "../../Export Reports/Reports";
import ReportModal from "../../Modal/ReportModal";
import VerifyOtpModal from "../../Modal/VerifyOtpModal";
import moment from "moment";

const ManageAdmin = () => {
  const { t } = useTranslation();
  const [modalTitle, setModalTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateFlow, setUpdateFlow, setUpdateFlowFileRef] = useStateRef("");
  const [details, setDetails] = useState({});
  const [uploadimgURL, setUploadimgURL] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(50);
  const [pageNo, setPageNo] = useState(1);
  const [searchValue, setSearchValue] = useState(null);
  const userDetails = getItem("user_details");
  const moduleArray = userDetails?.roleManagement?.admin;
  const [loading, setLoading] = useState(true);
  const [showPasswordField, setshowPasswordField] = useState(true);
  const [search, setSearch] = useState(null);
  const [citySelected, setCitySelected] = useState("");
  const [sort, setSort] = useState("DSC");
  const [sortKey, setSortKey] = useState("login_time");
  const [selectedTime, setSelectedTime] = useState("");
  const [imageSizeError, setImageSizeError] = useState(false);

  const navigate = useNavigate();
  const [showReportModal, setShowReportModal] = useState(false);
  const [docType, setDocType] = useState("");
  const [docName, setDocName] = useState("Admins Report");
  const [showVerify, setShowVerify] = useState(false);
  const reportKeys = ["Name", "Mobile No.", "Email Address", "City"];

  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedId("")
    setImageSizeError(false);

    setUploadimgURL("");
    setDetails({});
    form.resetFields();
    setUpdateFlow("");
    setshowPasswordField(true);
    // handleSearch("");
    // setSelectDep(0)
  };
  const deleteAdminFinal = async (id) => {
    setLoading(true);
    let remove = await dispatch(deleteAdminData(id));
    if (remove) {
      reteriveAdminList();
      setLoading(false);
    }
  };
  const deleteAdmin = (id) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this Admin?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteAdminFinal(id);
      },
    });
  };

  const { data, isLoading } = useQuery(
    "adminlist",
    () => {
      return reteriveAdminList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataCity, isLoading: isLoadingCity } = useQuery(
    "cityList",
    () => {
      return reteriveCityList();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    // reteriveAdminList();

    // reteriveCityList();
    window.scrollTo(0, 0);
  }, []);

  const reteriveAdminList = async () => {
    // setLoading(true);
    const resp = await dispatch(
      getAdminData(
        searchValue,
        pageNo,
        pageSize,
        citySelected,
        sortKey,
        sort,
        selectedTime
      )
    );
    if (resp) {
      // setLoading(false);
      setExportData(resp?.list);
      return resp;
    }
  };
  const adminList = useSelector((state) => state.admin.adminListdata);
  // console.log("adminList", adminList);

  const reteriveCityList = async () => {
    // setLoading(true);
    const resp = await dispatch(getCityData(""));
    if (resp) {
      // setLoading(false);
    }
  };
  let cityList = [];
  cityList = useSelector((state) => state.city.cityData);
  cityList = cityList?.sort((a, b) => a.name?.localeCompare(b.name));

  const handleSubmit = async (values) => {
    let formData = new FormData();
    formData.append("email", values.email);
    formData.append("city_id", values.city_id);
    formData.append("name", values.name.trim());
    formData.append("number", values.number);
    formData.append("profile_image", details.profile_image);
    if (values.password) {
      formData.append("password", values.password);
    }
    console.log("formData", details.profile_image);
    if (setUpdateFlowFileRef.current == "") {
      const resp = await dispatch(createAdminData(formData));
      if (resp) {
        setDetails({});
        setUploadimgURL("");
        reteriveAdminList();
        setIsModalOpen(false);
        setSearch(null);
      }
    } else {
      const resp = await dispatch(updateAdminData(formData, updateFlow));
      if (resp) {
        setDetails({});
        setUploadimgURL("");
        reteriveAdminList();
        setIsModalOpen(false);
        setshowPasswordField(true);
        setUpdateFlow("");
        form.resetFields();
        setSearch(null);
      }
    }
  };
  const editAdmin = (data) => {
    setIsModalOpen(true);
    // console.log(data);
    setModalTitle("Edit Admin");
    setUpdateFlow(data?._id);
    form.setFieldsValue({
      city_id: data?.city_id?._id,
      name: data.name,
      email: data.email,
      number: data.number,
      image: data.profile_image,
      password: "",
    });
    setDetails({ profile_image: data.profile_image });
    setshowPasswordField(false);
  };
  //CHANGE PAGE  FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setPageNo(page);
    await dispatch(
      getAdminData(
        searchValue,
        page,
        pageSizes,
        citySelected,
        sortKey,
        sort,
        selectedTime
      )
    );
  };

  //PAGE SIZE FUNCTION CALL
  const onShowSizeChange = async (current, pageSizes) => {
    setPageSize(pageSizes);
    await dispatch(
      getAdminData(
        searchValue,
        pageNo,
        pageSizes,
        citySelected,
        sortKey,
        sort,
        selectedTime
      )
    );
  };

  const handleSearch = async (value) => {
    setSearch(value);

    await dispatch(getCityData(value));
  };
  const [exportData, setExportData] = useState(adminList?.list);
  const modifiedExportData =
    exportData &&
    exportData.map((item, index) => ({
      "Sr. No.": index + 1 + (pageNo - 1) * pageSize,
      Name: item.name || "",
      "Mobile No.": item?.number || "",
      City: item?.city_id?.name || "-",
      "Email Address": item?.email || "-",
    }));

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setExportData(selectedRows);
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );
    },
  };

  const handleSearchFilter = async (value) => {
    setSearchValue(value);

    await dispatch(
      getAdminData(
        value,
        pageNo,
        pageSize,
        citySelected,
        sortKey,
        sort,
        selectedTime
      )
    );
  };

  const handleCitySelected = async (value) => {
    setCitySelected(value);

    if (search) {
      setSearch("");
      await dispatch(getCityData(""));
    }

    await dispatch(
      getAdminData(
        searchValue,
        pageNo,
        pageSize,
        value,
        sortKey,
        sort,
        selectedTime
      )
    );
  };

  const handleSelectTime = async (value) => {
    let val;
    if (value) val = value.format("YYYY-MM-DD");
    else {
      val = "";
    }
    setSelectedTime(val);
    setPageNo(1);
    await dispatch(
      getAdminData(
        searchValue,
        pageNo,
        pageSize,
        citySelected,
        sortKey,
        sort,
        val
      )
    );
  };
  const handleSort = async (key) => {
    let sorting;
    setSortKey(key);
    if (sort === "ASC") {
      setSort("DSC");
      sorting = "DSC";
    } else {
      sorting = "ASC";
      setSort("ASC");
    }
    await dispatch(
      getAdminData(
        searchValue,
        pageNo,
        pageSize,
        citySelected,
        key,
        sorting,
        selectedTime
      )
    );
    // setTableLoading(true);
    // sort=sort=="ASC"?setSort("DSC"):setSort("ASC")
  };

  const columns = [
    {
      title: t("srNo"),
      key: "_id",
      render: (text, object, index) => index + 1 + (pageNo - 1) * pageSize,
    },

    {
      title: t("profileImg"),
      key: "Image",
      render: (t, r) => {
        if (!r.profile_image) {
          return (
            <div>
              <Avatar
                size={50}
                icon={<FeatherIcon icon="user" size="30" />}
                style={{
                  backgroundColor: getRandomColor(),
                  height: "40px",
                  width: "40px",
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                }}
              />
            </div>
          );
        } else {
          return (
            <>
              <Avatar
                size={50}
                icon={
                  <img
                    style={{ borderRadius: "50%" }}
                    // crossOrigin="anonymous"
                    alt=""
                    src={`${IMAGE_URL_PREFIX}${r?.profile_image}`}
                  />
                }
              />
            </>
          );
        }
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: t("emailAddress"),
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: t("phoneNo"),
      dataIndex: "number",
      key: "number",
      align: "center",
    },
    {
      title: t("city"),
      key: "city",
      align: "center",
      render: (data) => {
        return <span>{data.city_id?.name}</span>;
      },
    },
    {
      title: (
        <div>
          Last Login{" "}
          <Button
            style={{ background: "none", border: "none" }}
            onClick={() => handleSort("login_time")}
          >
            <SwapOutlined rotate={90} style={{ color: "black" }} />
          </Button>
        </div>
      ),
      key: "lastLogin",
      render: (data, index1, index) => {
        // console.log(data.login_time,index1);
        const date = new Date();
        if (data.login_time) {
          const date2 = new Date(data.login_time);
          const difference = date.getTime() - date2.getTime();
          const differenceInMinutes = difference / (1000 * 60);
          const differenceInHours = difference / (1000 * 60 * 60);
          const differenceInDays = difference / (1000 * 60 * 60 * 24);

          if (differenceInMinutes < 60) {
            return (
              <span>
                {" "}
                <Tooltip title={date2.toLocaleString()}>
                  {" "}
                  {parseInt(differenceInMinutes)} minutes ago
                </Tooltip>
              </span>
            );
          } else if (differenceInHours < 24) {
            return (
              <span>
                <Tooltip title={date2.toLocaleString()}>
                  {" "}
                  {parseInt(differenceInHours)} hours ago
                </Tooltip>
              </span>
            );
          } else {
            return (
              <span>
                {" "}
                <Tooltip title={date2.toLocaleString()}>
                  {parseInt(differenceInDays)} days ago
                </Tooltip>
              </span>
            );
          }
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      title: t("action"),
      align: "center",
      render: (data) => {
        return (
          <div>
            <Button
              style={{ cursor: "pointer", marginRight: 2 }}
              type="primary"
              onClick={() => navigate(`captains/${data._id}`)}
            >
              <UserOutlined /> Captains
            </Button>
            {checkAction("admin", "edit") && (
              <Button
                onClick={() => editAdmin(data)}
                type="primary"
                // style={{ cursor: "pointer", color: "blue" }}
              >
                <EditOutlined />
                Edit
              </Button>
            )}
            {checkAction("admin", "delete") && (
              <Button
                onClick={() => deleteAdmin(data._id)}
                style={{ cursor: "pointer", marginLeft: 2 }}
                type="primary"
                danger
                disabled
              >
                <DeleteOutlined />
                Delete
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  // if(isLoading){
  //   return <Loader />
  // }
  return (
    <div
      className="common_layout 
     content-container
     "
    >
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1 className="heading">{t("admin")} </h1>
          <BreadCrumb />

          {/* Total 5 */}
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          {checkAction("admin", "add") && (
            <Button
              style={{ float: "right" }}
              className="ADD"
              key="1"
              size="large"
              type="primary"
              onClick={() => {
                setIsModalOpen(true);
                setModalTitle("Add New Admin");
                handleSearch("");
              }}
            >
              <PlusOutlined /> Add {t("admin")}
            </Button>
          )}
        </Col>
      </Row>
      <Divider />
      {isLoading & isLoadingCity ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <Card style={{ marginBottom: 10 }} className="shadowCardBox">
            <Row style={{ alignItems: "center" }} gutter={[16, 16]}>
              <Col xxl={6} xl={6} lg={4} md={8} sm={12} xs={24}>
                <span className="total">Total {adminList?.total_counts}</span>{" "}
              </Col>

              <Col xxl={4} xl={4} lg={4} md={8} sm={12} xs={24}>
                <Input
                  placeholder="Search By Name, Email Or Phone"
                  style={{
                    width: "100%",
                    float: "right",
                    // marginLeft: "10px",
                    // float: "right",
                  }}
                  prefix={<SearchOutlined />}
                  onChange={(event) => handleSearchFilter(event.target.value)}
                />
              </Col>

              {userDetails?.role == SUPERADMIN && (
                <Col xxl={5} xl={5} lg={5} md={8} sm={12} xs={24}>
                  <Select
                    mode={"multiple"}
                    placeholder="Choose city"
                    style={{
                      width: "100%",
                      maxHeight: "45px",
                      overflowY: "auto",
                      marginTop: "6px",
                    }}
                    showSearch
                    filterOption={false}
                    onSearch={(e) => handleSearch(e)}
                    allowClear
                    onChange={handleCitySelected}
                  >
                    {cityList.length > 0 &&
                      cityList?.map((data) => {
                        return (
                          <Select.Option
                            value={data._id}
                            key={data._id}
                            className="selectOptions"
                          >
                            {data.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Col>
              )}

              <Col xxl={5} xl={5} lg={6} md={8} sm={12} xs={24}>
                <DatePicker
                  showToday={false}
                  style={{
                    width: "100%",
                    // marginLeft: "10px",
                    borderRadius: 0,
                    // float: "right",
                  }}
                  placeholder={"Last Login Date"}
                  // format={"DD-MM-yyyy"}
                  onChange={(e) => handleSelectTime(e)}
                  format={"DD/MM/YYYY"}
                  disabledDate={(current) => current.isAfter(moment())}
                />
              </Col>
              {userDetails?.role == SUPERADMIN && (
                <Col xxl={4} xl={4} lg={5} md={8} sm={12} xs={24}>
                  <Select
                    placeholder="Export Report"
                    style={{
                      width: "100%",
                      // marginLeft: "10px",

                      // float: "right",
                    }}
                    value={null}
                    onSelect={(value) => {
                      // setShowReportModal(true);
                      setShowVerify(true);
                      // deleteKeys(value);
                      setDocType(value);
                    }}
                  >
                    <Select.Option value="csv" className="selectOptions">
                      {" "}
                      <FileOutlined /> CSV
                    </Select.Option>
                    <Select.Option value="excel" className="selectOptions">
                      {" "}
                      <FileExcelOutlined /> Excel (XLSX)
                    </Select.Option>
                    <Select.Option value="pdf" className="selectOptions">
                      {" "}
                      <FilePdfOutlined /> Pdf
                    </Select.Option>
                  </Select>
                </Col>
              )}
            </Row>
          </Card>
          <Card className="shadowCardBox">
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              rowSelection={{
                type: Checkbox,
                ...rowSelection,
              }}
              rowKey="_id"
              columns={columns}
              dataSource={adminList?.list}
              pagination={
                // adminList?.total_counts > pageSize
                //   ?
                {
                  showSizeChanger: true,
                  pageSize: pageSize,
                  total: adminList?.total_counts,
                  onShowSizeChange: onShowSizeChange,
                  onChange: onPageChange,
                  pageSizeOptions: ["50", "100", "500"],
                }
                // : false
              }
              scroll={{ x: "45vh" }}
            />
          </Card>
        </>
      )}
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={form.submit}
        // closable={true}
        // maskClosable={false}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          // labelCol={{ span: 5 }}
          autoComplete="off"
        >
          <Form.Item
            label="City"
            rules={[{ required: true, message: "Please Select City" }]}
            name="city_id"
          >
            <Select
              placeholder="Choose city"
              showSearch
              onSearch={(e) => handleSearch(e)}
              filterOption={false}
              allowClear
              onClear={(e) => handleSearch("")}
              disabled={setUpdateFlowFileRef.current ? true : false}
            >
              {cityList.length > 0 &&
                cityList?.map((data) => {
                  return (
                    <Select.Option value={data._id} key={data._id}>
                      {data.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item
            label={`Name`}
            rules={[
              {
                required: true,
                message: "Please Enter Name",

                pattern: new RegExp(/[^\s]+\s*$/i),
              },
            ]}
            name="name"
          >
            <Input type="text" placeholder="Enter Name"></Input>
          </Form.Item>
          <Form.Item
            label={t("emailAddress")}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please Enter Email Address",
              },
            ]}
            name="email"
          >
            <Input type="text" placeholder="Enter Email Address"></Input>
          </Form.Item>
          <Form.Item
            label={`Phone Numer`}
            rules={[
              { required: true, message: "Please Enter Phone Number" },
              {
                min: 10,
                message: "Please enter a valid 10 digit phone number",
              },
            ]}
            name="number"
          >
            <Input
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              type="text"
              maxLength={10}
              placeholder="Enter Phone Number"
            ></Input>
          </Form.Item>

          {/* {showPasswordField && ( */}
          <>
            <Form.Item
              label={`Password`}
              rules={[
                {
                  required: updateFlow ? false : true,
                  message: "Please Enter Password",
                },
                {
                  pattern: new RegExp(
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
                  ),
                  message: `Must contain at least one number , one uppercase, lowercase letter, and at least 8 or more characters`,
                },
              ]}
              name="password"
            >
              <Input.Password
                onKeyPress={(event) => {
                  if (event.key == " ") {
                    event.preventDefault();
                  }
                }}
                type="password"
                placeholder="Enter Password"
              ></Input.Password>
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: updateFlow ? false : true,
                  message: "Please enter confirm password",
                },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                onKeyPress={(event) => {
                  if (event.key == " ") {
                    event.preventDefault();
                  }
                }}
                placeholder="Confirm Password"
              />
            </Form.Item>
          </>
          {/* // )} */}
          <Form.Item
            name="image"
            // getValueFromEvent={normFile}
            label={
              <>
                Image
                <span style={{ marginLeft: "3px" }}></span>
              </>
            }
            rules={[
              {
                required: true,
                message: "Please choose Image",
              },
            ]}
          >
            <div className="vehicle_image">
              <label
                htmlFor="input-file"
                style={{
                  padding: details?.profile_image ? "0px" : "38px 26px",
                }}
              >
                {details?.profile_image ? (
                  <>
                    {(uploadimgURL !== "") & (uploadimgURL != undefined) ? (
                      <Avatar
                        size={100}
                        src={URL.createObjectURL(uploadimgURL)}
                      />
                    ) : (
                      <Avatar
                        size={100}
                        icon={
                          <img
                            src={IMAGE_URL_PREFIX + details?.profile_image}
                            // crossOrigin="anonymous"
                          />
                        }
                      ></Avatar>
                    )}
                  </>
                ) : (
                  <span>Upload</span>
                )}
              </label>
              <Input
                type="file"
                onChange={(event) => {
                  // setUploadimgURL(URL.createObjectURL(event.target.files[0]));
                  if (
                    parseInt(
                      (event.target.files[0].size / 1048576).toFixed(1)
                    ) <= 5
                  ) {
                    setUploadimgURL(event.target.files[0]);
                    setDetails({ profile_image: event.target.files[0] });
                    setImageSizeError(false);
                  } else {
                    setImageSizeError(true);
                  }
                }}
                accept="image/jpeg,image/png,"
                id="input-file"
                aria-label="Upload File"
                // defaultValue={uploadimgURL}
              />
            </div>
            {imageSizeError && (
              <p style={{ color: "red" }}>Maximum upload file size is 5MB</p>
            )}
          </Form.Item>
        </Form>
      </Modal>
      <ReportModal
        showReportModal={showReportModal}
        setShowReportModal={setShowReportModal}
        modifiedExportData={modifiedExportData}
        docType={docType}
        reportKeys={reportKeys}
        docName={docName}
      />
      <VerifyOtpModal
        showVerify={showVerify}
        setShowVerify={setShowVerify}
        setShowReportModal={setShowReportModal}
        type={"Admin"}
      />
    </div>
  );
};

export default ManageAdmin;
